.map-container {
	height: 500px;
	margin-bottom: 20px;

	:global {
		.mapboxgl-popup-close-button {
			font-size: 24px;
		}
	}

	.popup {
		.title {
			font-size: 16px;
			margin-bottom: 10px;
		}

		.icon {
			text-align: center;
			max-width: 100px;
			margin: 0 auto;
		}
	}
}