@import "../../../../assets/media";

.traveller-info {
	display: flex;
	background: var(--background-color-secondary);
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
	border-radius: 10px;
	color: var(--text-color-primary);

	* {
		box-sizing: border-box;
	}

	p {
		padding: 0;
		margin: 0;
		font-weight: 400;
	}

	.row-1 {
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include mobile-down {
			flex-direction: column;
		}
	}

	.col-1 {
		padding: 20px;
	}

	.title {
		font-size: 26px;
		font-weight: normal;
		line-height: 1.44;
		padding: 0;
		margin: 0;
		opacity: 0;
		transition: 0.7s;

		&.head-done-enter {
			opacity: 1;
		}
	}

	.locations {
		overflow: hidden;
		margin-top: 12px;
	}

	.location {
		display: flex;
		align-items: center;
		margin: 0 0 12px;
	}

	.location-icon {
		font-size: 24px;
		color: var(--text-color-secondary);
		margin-right: 4px;
	}

	.location-name {
		font-size: 16px;
		color: var(--text-color-primary);
		font-weight: 700;
		line-height: 1.5;
		padding: 0;
		margin: 0;
	}

	.risk-brief-location-name {
		font-size: 20px;
	}

	.description {
		font-size: 13px;
		color: var(--text-color-secondary);
		line-height: 1.67;
		word-break: break-all;
	}

	.processing {
		@include mobile-down {
			margin-bottom: 21.71px;
		}
	}

	.col-2 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
		width: 100%;
		background: #dee2e5;
		border-radius: 0 0 10px 10px;
		padding: 22px 18px 18px 24px;

		@include tablet-up {
			max-width: 313px;
			border-radius: 0 10px 10px 0;
		}
	}

	.traveller-person-header {
		display: flex;
		align-items: center;
	}

	.traveller-person-icon {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--background-color-primary);
		border-radius: 32px;
		font-size: 24px;
		font-weight: 400;
		margin-right: 8px;
	}

	.traveller-person-title {
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		padding: 0;
		margin: 0;
	}

	.traveller-person-body {
		margin-top: 20px;
		color: var(--text-color-secondary);
	}

	.traveller-person-data-wrap {
		display: flex;
		margin-bottom: 12px;
		font-size: 13px;
	}

	.traveller-label {
		min-width: 80px;
		margin-right: 18px;
	}

	.traveller-data {
		font-weight: 700;
		word-break: break-all;

		@include tablet-up {
			max-width: 170px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
