div.accordion {
	display: flex;
	flex-direction: column;
	gap: 12px;
	position: relative;
	padding-bottom: 40px;

	.link-read-more {
		position: absolute;
		bottom: 0;
	}

	.description {
		display: flex;
		flex-direction: column;
		gap: 12px;
		overflow: hidden;
		transition: 0.3s ease-out;
	}

	:global {
		.link-text {
			white-space: nowrap;
		}
	}
}
