@import "../../assets/colors";

.world {
    svg path {

        // fill: $gray;
        &:hover {
            stroke-width: 3;
            stroke: #18a0fb;
        }
    }

    .tooltip-country {
        position: fixed;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.7);
        box-shadow: 0 5px 10px rgba(0, 24, 32, 0.07);
        backdrop-filter: blur(2px);
        border-radius: 20px 20px 20px 0;
        padding: 6px 8px 6px 12px;
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity 0.3s;
        }

        .tooltip-country-name {
            font-size: 16px;
            color: $white;
            font-weight: 700;
            line-height: 1.4;
        }

        .tooltip-country-index {
            font-size: 16px;
            color: $white;
            line-height: 1.4;
            margin-left: 4px;

            .tooltip-icon {
                display: flex;
                align-items: center;
                font-size: 20px;
                font-variation-settings: "wght" 400;
            }

            .military-icon {
                color: #ff0000;
            }
        }
    }
}

@media only screen and (min-width: 1023px) {
    .world {
        padding-left: 20px;
        padding-right: 20px;
    }
}