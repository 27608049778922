@import "../../assets/colors";

.comp-for-personal-risk {
    margin: 0 60px;

    .row {
        display: flex;
        flex-direction: row;
    }

    .row-space {
        margin-top: 10px;
    }

    .empty {
        height: 60px;
        background-color: transparent;
    }

    .base {
        background-color: #FFCB00;
        height: 60px;
    }

    .base-dark {
        background-color: #FFB601;
        height: 60px;
    }

    .delta {
        background-color: #9356f8;
        height: 60px;
    }

    .delta-dark {
        background-color: #7B3CE3;
        height: 60px;
    }
}
