.report-title {
	color: var(--text-color-primary);
	margin-bottom: 30px;

	.title {
		font-weight: 400;
		margin: 0;
	}

	.sub-title {
		font-size: 13px;
		color: var(--text-color-secondary);
		margin: 0;
	}
}
