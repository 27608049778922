.map {
    position: absolute !important;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
}

@media screen and (max-width: 1399px) {
    .map {
        position: fixed !important;
    }
}
