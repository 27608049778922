@mixin wider-than($min-width) {
	@media (min-width: $min-width) {
		@content;
	}
}

@mixin narrower-than($max-width) {
	@media (max-width: $max-width - 1px) {
		@content;
	}
}

@mixin between($min-width, $max-width) {
	@media (min-width: $min-width) and (max-width: $max-width - 1px) {
		@content;
	}
}

$mobile: 320px !default;
$phablet: 480px !default;
$tablet: 768px !default;
$desktop: 1024px !default;
$bigdesktop: 1440px !default;
$bigdesktopfornav: 1660px !default;

@mixin phablet-up {
	@include wider-than($phablet) {
		@content;
	}
}

@mixin tablet-up {
	@include wider-than($tablet) {
		@content;
	}
}

@mixin desktop-up {
	@include wider-than($desktop) {
		@content;
	}
}

@mixin bigdesktop-up {
	@include wider-than($bigdesktop) {
		@content;
	}
}
@mixin bigdesktop-down {
	@include narrower-than($bigdesktop) {
		@content;
	}
}
@mixin bigdesktopfornav-down {
	@include narrower-than($bigdesktopfornav) {
		@content;
	}
}

@mixin mobile-down {
	@include narrower-than($tablet) {
		@content;
	}
}

@mixin phablet-down {
	@include narrower-than($phablet) {
		@content;
	}
}

@mixin tablet-down {
	@include narrower-than($desktop) {
		@content;
	}
}

@mixin desktop-down {
	@include narrower-than($bigdesktop) {
		@content;
	}
}

@mixin mobile-only {
	@include between($mobile, $tablet) {
		@content;
	}
}

@mixin tablet-only {
	@include between($tablet, $desktop) {
		@content;
	}
}

@mixin desktop-only {
	@include between($desktop, $bigdesktop) {
		@content;
	}
}
