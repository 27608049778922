@import "../../assets/colors";

.geographic-risk-trends-container {
    // background: $white;
    // width: 100%;
    // min-height: 208px;
    // box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
    // border-radius: 10px;
    // padding: 2.6rem 2rem 2rem 3rem;
    // margin-top: 3rem;
    // position: relative;

    .header {
        display: flex;
        align-items: center;

        .block-text {
            margin-left: 10px;

            h3 {
                font-size: 1.2857rem;
                color: $blueDark;
                font-weight: 400;
                margin: 0;
            }

            span {
                display: inline-block;
                font-size: 0.928rem;
                color: $gray;
                margin-top: 3px;
            }
        }

    }

    .box-graph {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .block-graph-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding-right: 0.5rem;
        }

        .block-graph-right {
            min-width: 130px;
            padding-left: 0.5rem;
            margin-top: -7px;

            .graph-color-header {
                font-size: 0.7857rem;
                color: $blueDark;
                text-align: center;
                margin-bottom: 5px;
                align-self: center;
            }

            .graph-color-item-02 {
                margin-top: 8px;
            }

            .data-arrow {
                display: flex;
                align-items: center;
            }
            h2 {
                font-size: 1.857rem;
                font-weight: 400;
                margin: 0 2px 0 5px;
            }

            svg {
                width: 20px;
                height: 20px;
            }

            span {
                font-size: .928rem;
                color: $gray;
                margin-left: 2.8rem;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .geographic-risk-trends-container {
        h3 {
            font-size: 16px !important;
        }
        .box-graph {
            flex-direction: column;

            .block-graph-right {
                border: none;
                padding-left: 0;

                .graph-color {
                    display: flex;
                    justify-content: space-around;
                    margin-top: 15px;
                }
            }
        }
        .graph-color-header, 
        .graph-color-item {
            margin: 0 !important;
        }

        
    }
}
