@import '../../../assets/media';

.tabs-container {
	.tabs-header {
		display: flex;
		align-items: center;
		gap: 5px;
		flex-wrap: wrap;
		position: relative;
	}

	.tab-button {
		display: flex;
		align-items: center;
		min-height: 40px;
		padding: 5px 20px;
		gap: 8px;
		background: var(--button-tab-background-primary);
		border: none;
		border-radius: 10px 10px 0 0;
		clip-path: inset(-5px -5px 0 -5px);
		cursor: pointer;
		transition: 0.25s;
		font-size: 16px;
		line-height: 22.4px;
		text-align: center;
		box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);

		color: var(--textGray);

		&.active {
			background: var(--background-color-secondary);
			color: var(--newLink);
			font-weight: 700;

			:global {
				.color-emergency {
					color: var(--icon-color-one);
				}

				.color-encrypted {
					color: var(--icon-color-two);
				}

				.color-dangerous {
					color: var(--icon-color-three);
				}
			}
		}

		.btn-icon {
			font-size: 18px;
			display: flex;
			align-items: center;
			font-weight: 400;
			color: var(--textGray);
		}

		.btn-text {
			font-size: 16px;
			color: inherit;
		}
	}

	.tab-content {
		padding: 24px;
		background: var(--background-color-secondary);
		border-radius: 0 10px 10px 10px;

		&.rounded {
			border-radius: 0 10px 10px 10px;
			box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		}
	}
}
