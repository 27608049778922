$white: #fff;
$blueDark: #233544;
$blueMediumDark: #334b5d;
$gray: #7d8c97;
$grayLight: #dee2e5;
$violet: #9356f8;
$cyanLight: #7ef2f3;
$health: #FFCB00;
$crime: #FF0000;
$green: #6AD400;

/*Heatmap colors*/
.heatmap-00 {
	background: $grayLight;
}

.heatmap-01 {
	background: #6AD400;
} /* 0 - 0.01*/

.heatmap-02 {
	background: #97D101;
} /* 0.01 - 1*/

.heatmap-03 {
	background: #B5CF01;
} /* 1 - 10*/

.heatmap-04 {
	background: #D3CE01;
} /* 10 - 50*/

.heatmap-05 {
	background: #FFCB00;
} /* 50 - 100*/

.heatmap-06 {
	background: #FFB601;
} /* 100 - 200*/

.heatmap-07 {
	background: #FF8E01;
} /* 200 - 400*/

.heatmap-08 {
	background: #FF6401;
} /* 400 - 600*/

.heatmap-09 {
	background: #FF3C01;
} /* 600 - 800*/

.heatmap-10 {
	background: #FF0000;
} /* 800 - 1000*/

.heatmap-11 {
	background: #C10000;
} 

.heatmap-12 {
	background: #390505;
 }

/*Heatmap colors (blue - violet)*/
.blue-heatmap-01 {
	background: #1ff1f2;
} /* 0 - 0.01*/

.blue-heatmap-02 {
	background: #2eddf3;
} /* 0.01 - 1*/

.blue-heatmap-03 {
	background: #40c5f4;
} /* 1 - 10*/

.blue-heatmap-04 {
	background: #4bb6f5;
} /* 10 - 50*/

.blue-heatmap-05 {
	background: #54aaf5;
} /* 50 - 100*/

.blue-heatmap-06 {
	background: #6298f6;
} /* 100 - 200*/

.blue-heatmap-07 {
	background: #6e87f6;
} /* 200 - 400*/

.blue-heatmap-08 {
	background: #7c75f7;
} /* 400 - 600*/

.blue-heatmap-09 {
	background: #826df7;
} /* 600 - 800*/

.blue-heatmap-10 {
	background: #9356f8;
} /* 800 - 1000*/