@import "../../assets/colors";

.personal-risk-static-info-container {
    max-height: 420px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -20px;
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border: 4px solid transparent;
        background-clip: content-box; 
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $grayLight;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $grayLight;
    }

    .block-img {
        // max-height: 181px;
        margin: 20px 0;
        text-align: center;

        img {
            width: 100%;
            height: auto;
        }
    }

    .block-text {
        font-size: .928rem;
        color: $gray;
        border-bottom: none;
    }
}
