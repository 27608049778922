.loader {
	background: var(--background-color-secondary);
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	box-shadow: 0px 5px 10px 0px rgba(0, 24, 32, 0.07);
	padding: 20px;
	font-size: 18px;
	opacity: 0;
	transition: 0.5s;

	&:global(.loader-enter-done) {
		opacity: 1;
	}
}
