@import "../../assets/colors";

.geographic-risk-container {
    background: $white;
    width: 100%;
    // min-height: 408px;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
    // border-radius: 10px;

    h2 {
        font-size: 2rem;
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 2.4rem 2.4rem 0 2rem;
        padding-bottom: 1.5rem;
    }

    .box-graph {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .graph-top {
            max-width: 220px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: center;

            .block-text-location {
                width: 100%;
                color: $blueDark;
                text-align: center;
                margin-top: -0.5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        // .block-map {
        //     padding: 2.4rem 1rem 1rem;
        // }

        // .block-map-personal {
        //     padding: 2.2rem 1rem 1.6rem;
        // }

        .footer {
            margin-top: -0.3rem;
            .footer-content {
                display: flex;
                justify-content: space-between;
                padding-bottom: 2rem;
                text-align: center;
                padding: 0 1rem 1rem;

                .left-cont-block {
                    font-size: 1.3rem;
                    color: $gray;
                    text-align: left;
                    margin-right: 1.5rem;
                }

                .right-cont-block {
                    button {
                        padding-left: 3.2rem;
                        padding-right: 3.2rem;
                    }
                    & span {
                        font-size: 1.2rem;
                        color: inherit;
                        font-weight: 700;
                        letter-spacing: 0.05em;
                    }
                }
            }
        }
    }

    span {
        font-size: 1.3rem;
        color: $gray;
    }
}

@media only screen and (max-width: 767px) {
    .geographic-risk-container {
        .footer-content {
            align-items: center;
        }
    }
}