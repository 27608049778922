@import './assets/colors';
@import './assets/media';
@import './assets/colorConstants';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

html,
body,
#root {
	margin: 0;
	padding: 0;
	height: 100%;
}

html {
	font-size: 14px;
	font-family: 'Arimo', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #334b5d inset !important;
	-webkit-text-fill-color: white !important;
}
form.light {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px #fff inset !important;
		-webkit-text-fill-color: #233544 !important;
	}

	.success {
		color: #43a047;
	}
}

h1 {
	font-size: 40px;
	@include mobile-down {
		font-size: 32px;
	}
}

h2 {
	font-size: 32px;
	@include mobile-down {
		font-size: 22px;
	}
}

h3 {
	font-size: 26px;
	@include mobile-down {
		font-size: 20px;
	}
}

a,
a:visited {
	color: #7d8c97;
	text-decoration: none;
}

ul {
	padding: 0;
	margin: 0;
}

button:focus {
	outline: 0;
}

.scrollbar1::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	background-color: rgb(66, 83, 97);
}
.scrollbar1::-webkit-scrollbar {
	width: 8px;
	background-color: rgb(66, 83, 97);
	border-radius: 8px;
}
.scrollbar1::-webkit-scrollbar-thumb {
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: rgb(125, 140, 151);
}

.overflow-row-1 {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.overflow-row-3 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	// max-height: 60px;
}

.overflow-row-5 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	// max-height: 60px;
}

.preloading {
	display: flex;
	flex: 1;
	height: 100%;
	background-color: #334b5d;
	justify-content: center;
	align-items: center;

	#logo {
		animation: rotating 2s linear infinite;
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.arrow-up {
	color: $crime;
}

.arrow-down {
	color: $green;
}

.btn-copy {
	cursor: pointer;
}

.layout-hide-children {
	> .map,
	> * > * {
		display: none;
	}

	.home-report {
		display: block !important;
		z-index: unset;
	}
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.material-symbols-outlined {
	font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' -25, 'opsz' 48;

	&.fw-100 {
		font-variation-settings: 'wght' 100;
	}
	&.fw-200 {
		font-variation-settings: 'wght' 200;
	}
	&.fw-300 {
		font-variation-settings: 'wght' 300;
	}
	&.fw-400 {
		font-variation-settings: 'wght' 400;
	}
	&.fw-500 {
		font-variation-settings: 'wght' 500;
	}
	&.fw-600 {
		font-variation-settings: 'wght' 600;
	}
	&.fw-700 {
		font-variation-settings: 'wght' 700;
	}
}

.section-wrap-white {
	background: var(--background-color-secondary);
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
}

.icons-animation {
	transition: 0.3s !important;

	&:hover {
		transform: scale(1.2);
		color: $violet !important;
	}
}

.flex-auto {
	flex: auto;
}
