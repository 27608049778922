@import "../../assets/colors";
@import "../../assets/colors";

.similar-cities-nodata {
	background: $grayLight;
	box-sizing: border-box;
	width: 293px;
	height: 96px;
	box-shadow: 0 5px 10px rgba(0, 24, 32, 0.07);
	border-radius: 10px;
	padding: 8px 20px;
	color: $blueDark;

	.content-wrap {
		display: flex;
		justify-content: space-between;
		height: 100%;
	}

	.left-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		width: 60%;
		margin-top: 5px;

		.text-wrap {
			width: 100%;
		}

		.location {
			font-size: 16px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.location-title {
			font-size: 12px;
		}
	}

	.right-block {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		text-align: right;

		.nodata-icon {
			max-width: 12.5rem;
		}

		.text-block {
			font-size: 12px;
			font-weight: 700;
			line-height: 1.1;
			margin-top: 11px;
		}
	}

	@media only screen and (max-width: 767px) {
		width: 100%;
	}
}
