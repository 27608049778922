@import '../../../../../assets/media';

.bar-label {
	height: 100%;
	width: 150px;
	.label-container {
		position: relative;
		padding-top: 10px;
		.line {
			height: 178px;
			//TODO: extra style for RPO?
			// width: 1px;
			border: 1px solid #dee2e5;
			position: absolute;
			z-index: 0;
			left: calc(5% + 11px);
			top: 5%;
		}
		.location {
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0px 1px 6px 0px rgba(0, 24, 32, 0.1);
			position: absolute;
			z-index: 2;
			left: 5%;
			top: 5%;
			background: var(--white);
			border-radius: 24px;
			width: auto;
			min-width: 24px;
			height: 24px;
			//TODO: extra style for RPO?
			// if we will display the locations name
			// padding: 0 3px;

			.location-icon {
				font-size: 18px;
				color: var(--blueDark);
			}
			.location-text {
				padding-right: 3px;
				line-height: 0px;
				@include mobile-down {
					font-size: 14px;
				}
			}
		}
	}
}
