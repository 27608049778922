:global {
	.risk-safety-summary-container {
		:global {
			.icon-emergency {
				font-weight: 400;
				color: var(--icon-color-one);
			}

			.icon-encrypted {
				font-weight: 400;
				color: var(--icon-color-two);
			}

			.icon-dangerous {
				font-weight: 400;
				color: var(--icon-color-three);
			}
		}

		:local {
			.button {
				display: flex;
				align-items: center;
				min-height: 40px;
				padding: 5px 20px;
				gap: 8px;
				background: var(--button-tab-background-primary);
				border: none;
				border-radius: 6px;
				cursor: pointer;
				transition: 0.25s;

				&:hover:not(.disabled) {
					box-shadow: 0px 4px 8px 0px rgba(0, 24, 32, 0.12);
				}

				&:active:not(.disabled) {
					box-shadow: 0px 1px 2px 0px rgba(0, 24, 32, 0.24);
				}

				&.active {
					background: var(--button-tab-background-primary-active);

					.btn-text {
						color: var(--button-tab-text-color-primary-active);
					}

					:global {
						.btn-icon {
							color: var(--icon-color-four);
						}
					}
				}

				&.disabled {
					cursor: auto;
				}

				.btn-icon {
					font-size: 18px;
					display: flex;
					align-items: center;
				}

				.btn-text {
					font-size: 16px;
					color: var(--button-text-color-secondary);
				}
			}
		}
	}
}
