// TODO: need to refactor after global styles h1/h2/h3... change
.section-title {
	color: var(--text-color-primary);
	line-height: 1.36;
	.title {
		// font-size: 32px;
		font-weight: 400;
	}
	.sub-title {
		font-size: 16px;
		font-weight: 400;
	}

	h2 {
		// font-size: 32px;
		font-weight: 400;
		margin: 0;
		// padding: 0;
	}

	p {
		font-size: 16px;
		margin: 0;
		// padding: 0;
	}
}
