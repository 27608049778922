.summary-tab {
	.header {
		margin-bottom: 12px;
		// TODO: need to remove?
		// font-size: 26px;
		font-weight: 400;
		line-height: 1.44;
	}

	.body-section {
		line-height: 1.6;
		font-size: 16px;
	}
}

.headered-list {
	display: flex;
	flex-direction: column;
	gap: 12px;
	.list-item {
		.list-item-header {
			margin-bottom: 12px;
		}
	}
}

.ordered-list {
	list-style: disc;
	margin-left: 25px !important;
	.list-item {
		margin-bottom: 5px;
		.list-item-header {
			font-weight: bold;
		}
		.list-item-content {
			margin-bottom: 12px;
		}
	}
}

.paragraph-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
	p {
		// text-indent: 20px;
	}
}
