.risk-safety-summary-container {
	opacity: 0;
	transition: 0.5s;

	* {
		padding: 0;
		// TODO: need to check
		margin: 0;
	}

	// &:global(.risk-safety-summary-enter-active) {
	// 	opacity: 1;
	// }

	&:global(.risk-safety-summary-enter-done) {
		opacity: 1;
	}
}

section.risk-safety-summary-container {
	min-height: 100vh;
	// background: var(--background-color-primary);
	color: var(--text-color-primary);
	font-size: 16px;
	line-height: 1.4;

	h2 {
		// font-size: 32px;
		font-weight: 400;
		line-height: 1.36;
	}

	h3 {
		// font-size: 26px;
		font-weight: 400;
		line-height: 1.44;

		// @media only screen and (max-width: 767px) {
		// 	font-size: 22px;
		// }
	}

	h4 {
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		margin-top: 0;
	}

	.content {
		width: 100%;
		max-width: 1020px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin-left: auto;
		margin-right: auto;
	}

	.section {
		display: flex;
		flex-direction: column;
		padding: 20px;
	}

	.section-widget-wrap {
		background: var(--background-color-secondary);
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		box-shadow: 0px 5px 10px 0px rgba(0, 24, 32, 0.07);
	}
}
