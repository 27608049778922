@import '../../../../assets/media';

$colorLevel3: #ff6401;
$colorLevel2: #ffb601;
$colorLevel1: #b5cf01;

.health-notice {
	border-radius: 10px;
	overflow: hidden;
	background: var(--background-color-secondary);
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);

	&:last-of-type {
		margin-bottom: 0;
	}

	& * {
		box-sizing: border-box;
	}

	.notice-header {
		display: flex;
		align-items: center;
		min-height: 40px;
		font-size: 16px;
		font-weight: 400;
		line-height: 22.4px;
		color: var(--white);
		padding: 10px 20px;
	}

	.notice-header-text {
		padding-left: 14px;
	}

	.notice-content {
		padding-top: 15px;
	}

	.notice-item {
		color: var(--blueDark);

		&:not(:first-child) {
			margin-top: 15px;
		}

		&:last-child {
			border: none;
		}
	}

	.top-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
	}

	.qrcode {
		@include mobile-down {
			display: none;
		}
	}

	.bottom-block {
		min-height: 40px;
		display: flex;
		align-items: center;
		padding: 0 20px;
		background: rgba(222, 226, 229, 0.2);
	}

	.left {
		margin-right: 20px;
	}

	.title {
		color: var(--text-color-primary);
		// margin: 0 0 4px;

		font-size: 20px;
		font-weight: 400;
		margin: 0;
	}

	.date {
		color: var(--text-color-secondary);
		font-size: 13px;
		line-height: 1.3;
		margin: 0;
	}

	.description {
		margin-top: 8px;
		margin-bottom: 16px;
		font-size: 16px;
		font-weight: 400;
		color: var(--text-color-primary);
		line-height: 22.4px;
	}

	.url {
		font-size: 16px;
		font-weight: 400;
		line-height: 22.4px;

		a {
			display: flex;
			color: var(--newLink);
		}

		.url-text {
			width: 100%;
			word-break: break-word;
			margin-left: 8px;
		}
	}

	&.health-notice-level-3 {
		& .notice-header {
			background-color: $colorLevel3;
		}
	}

	&.health-notice-level-2 {
		& .notice-header {
			background-color: $colorLevel2;
		}
	}

	&.health-notice-level-1 {
		& .notice-header {
			background-color: $colorLevel1;
		}
	}
}
