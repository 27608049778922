.statistics {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 10px;

    border-radius: 24px;
    background-color: #fff;
    box-shadow: 0px 1px 6px 0px rgba(0, 24, 32, 0.1);
    padding: 8px;

    .item {
        display: flex;
        gap: 10px;
        .text {
            font-size: 13px;
            font-weight: 400;
        }
        .number {
            font-size: 13px;
            font-weight: 700;
        }
    }
}