.body {
	&:global(.__react_component_tooltip) {
		max-width: 258px;
		z-index: 2;
		color: #fff;
		padding: 10px;
		font-size: 11px;
		line-height: 14px;
		border-radius: 3px;

		a {
			color: var(--cyanLight);
		}
	}
}

.icon {
	line-height: 10px;
	color: var(--gray);
	vertical-align: text-top;
	svg {
		font-size: 22px;
	}
}
