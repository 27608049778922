@import "../../assets/colors";

.violent-crimer-risk-nodata-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 27px 20px;
	height: 100%;
	// background: repeating-linear-gradient(45deg, #fff, #fff 10px, #f8f9f9 10px, #f8f9f9 20px);

	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 48px;

		.block-text {
			display: flex;

			h2 {
				margin-right: 5px;
			}

			.tip-wrap {
				display: inline-block;

				.block-info-icon {
					color: #7d8c97;
					cursor: pointer;
					margin: 3px 0 0 5px !important;

					&:hover {
						color: $violet;
					}
				}
			}
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		.nodata-img {
			// max-width: 108px;
			// width: 100%;
			height: 100%;
		}

		.title {
			margin: 19px 0 8px;
		}
		.nodata-destination-location {
			width: 100%;
			text-align: center;
			margin-top: -2rem;
		}

		.nodata-description {
			color: $gray;
			margin-top: 2rem;
		}
	}
}
