@import "../../assets/colors";

.statistics-map-container {
    background: $white;
    box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
    border-radius: 10px;

    .header {
        display: flex;
        justify-content: space-between;
        padding: 21px 15px 0;

        h3 {
            font-size: 1.4rem;
            color: $blueDark;
            font-weight: 400;
            margin: 0;
            letter-spacing: -0.8px;

            p {
                font-size: 0.928rem;
                color: $gray;
                letter-spacing: normal;
                margin: 3px 0 0;
            }
        }

        .block-right {
            flex-direction: row !important;
            justify-content: flex-end !important;
        }

    }

    .box-map {
        padding: 0 30px 30px;

        .block-map-scale {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;

            .scale-item {
                width: 10%;
                height: 65px;
                border-left: 1px solid rgba(35, 53, 68, 0.1);
                position: relative;
                font-size: 0.928rem;
                color: $blueDark;

                &:last-of-type {
                    border-right: 1px solid rgba(35, 53, 68, 0.1);
                }

                .heatmap {
                    display: block;
                    height: 10px;
                    border-radius: 10px;
                    margin-top: 10px;
                }

                .location-title {
                    display: block;
                    text-align: right;
                    color: $gray;
                    padding: 7px;
                    box-sizing: border-box;
                }

                .stat-num-devider,
                &:not(:last-of-type) .stat-num-two {
                    display: none;
                }

                .stat-num {
                    position: absolute;
                    top: -16px;
                    left: 0;
                    transform: translateX(-50%);
                    color: $blueDark;
                }

                .stat-num-two {
                    left: 100%;
                }
            }
        }
    }
}

@media only screen and (min-width: 1023px) {
    .block-map-scale {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .statistics-map-container {
        .header {
            h3 {
                font-size: 16px;
            }

            .block-right {
                flex-direction: column-reverse !important;
                align-items: flex-end;
                margin: 0 0 0 10px;
            }
        }

        // .block-map {
        //     overflow: auto;
        //     .world {
        //         min-width: 767px;
        //     }
        // }

        .box-map {
            padding-left: 15px;
            padding-right: 15px;

            .block-map-scale {
                flex-direction: column;

                .scale-item {
                    width: 100%;
                    min-height: 40px;
                    height: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: none !important;
                    border-top: 1px solid rgba(35, 53, 68, 0.1) !important;

                    &:last-of-type {
                        border-bottom: 1px solid rgba(35, 53, 68, 0.1) !important;
                    }

                    .heatmap {
                        width: 20px;
                        margin-top: 0;
                        margin-right: 10px;
                    }

                    .box-stat {
                        display: flex;
                        align-items: center;
                    }

                    .stat-num,
                    .stat-num-devider {
                        display: inline-block !important;
                        position: static;
                        transform: none;
                    }
                }
            }
        }
    }
}