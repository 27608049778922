@import "../../assets/colors";

.switch {
    background-color: $blueMediumDark;
    height: 42px;
    border-radius: 20px;
    padding: 2px;
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .switch-selector {
        position: absolute;
        // width: 50%;
        height: calc(100% - 4px);
        background: $white;
        border: 0.5px solid rgba(0, 0, 0, 0.101987);
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        transition: 0.2s;
    }

    label {
        flex: 1;
        position: relative;
        transition: 0.2s;
        font-size: 10px;
        color: $gray;
        font-weight: bold;
        line-height: 133%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-align: center;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        cursor: pointer;
    }

    label.active {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: $white;
        border-radius: 20px;
        transition-delay: .1s;
        transition-property: background-color;

        color: $blueDark;
    }
}
