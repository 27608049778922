@import '../../assets/colors';

.violent-crimer-risk-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	// TODO: need to check
	// padding: 24px;
	opacity: 0;
	transition: opacity 1s ease;

	&.visible {
		opacity: 1;
	}

	.title {
		font-size: 26px;
		font-weight: 400;
		color: $blueDark;
		margin: 0 0 10px;
		padding: 0;
	}

	.sub-title {
		font-size: 16px;
		font-weight: 400;
		color: $blueDark;
		margin: 0;
		padding: 0;
	}

	.graph-wrap {
		display: flex;
		justify-content: space-between;
	}

	.left-box {
		margin-top: 10px;
	}

	.similar-cities-content {
		display: flex;
		margin-top: 18px;

		.similar-cities {
			box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		}

		.similar-cities-info {
			margin-left: 21px;
			padding: 8px 0;

			.similar-cities-info-header {
				display: flex;

				.graph-color-icon {
					min-width: 16px;
				}
			}

			h3 {
				font-size: 16px;
				font-weight: 400;
				line-height: 115%;
				margin: 0 0 5px;
			}

			p {
				max-width: 180px;
				font-size: 13px;
				color: $gray;
				line-height: 1.5;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0;
			}
		}
	}

	.graph-top {
		width: 437px;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: center;

		.value-scale {
			opacity: 0;

			&.active {
				opacity: 1;
			}
		}

		.block-text-index {
			color: $gray;
			margin-top: -61px;
		}

		.block-text-location {
			max-width: 55%;
			width: 100%;
			min-height: 22px;
			font-size: 20px;
			color: $blueDark;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.block-text-location-mobile {
			max-width: none;
			display: none;
		}

		.block-text-state {
			width: 100%;
			display: flex;
			justify-content: space-between;
			font-size: 13px;
			color: $gray;
			padding: 5px 23px;
			margin-top: -10px;
		}
	}
}

@media only screen and (max-width: 1023px) {
	.violent-crimer-risk-container {
		.right-box {
			max-width: 300px;
			width: 100%;
		}
		.graph-top {
			width: 100%;
		}

		.similar-cities-content {
			flex-direction: column;

			.similar-cities-right {
				margin: 15px 0 0;
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.violent-crimer-risk-container {
		.graph-top {
			.block-text-index {
				margin-top: -45px !important;
			}

			.block-text-location {
				margin-top: 11px;
			}

			.block-text-state {
				margin-top: -24px;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.violent-crimer-risk-container {
		.graph-wrap {
			flex-direction: column-reverse;
			align-items: center;

			.left-box {
				margin-top: 0;
				width: 100%;
			}

			.similar-cities-home {
				margin-top: 25px;
			}

			.similar-cities-info-text {
				width: 100%;

				p {
					max-width: none;
					padding-right: 20px;
				}
			}
		}

		.right-box {
			margin-top: 25px;
		}

		.graph-top {
			.block-text-state {
				padding-left: 0;
				padding-right: 0;
			}

			.block-text-index {
				margin-top: -30px !important;
			}

			.block-text-location-desktop {
				display: none !important;
			}

			.block-text-location-mobile {
				display: block !important;
			}

			.block-text-index {
				margin-top: -45px;
			}
		}
	}
}
