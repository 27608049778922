@import '../../assets/colors';

.personal-risk-container {
  p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 140%;
  }

  .container-switch-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .block-btn-switch {
    // max-width: 334px;
    width: 100%;
    margin-right: 15px;
  }

  .block-info-icon {
    display: flex;
    align-items: center;
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $violet;
    }
  }

  .tooltip-btn-default {
    display: flex;
    justify-content: space-between;
  }

  .block-text-mobile {
    .share-icon {
      display: none;
    }
  }

  h2 {
    font-weight: 400;
    color: $blueDark;
    margin: 0 0 5px;
  }

  .block-location {
    display: flex;
    align-items: center;
  }

  .block-location-text {
    font-size: 1.143rem;
    color: $blueDark;
    margin-left: 5px;
  }

  .sub-line {
    display: block;
    font-size: 0.929rem;
    color: $gray;
    line-height: 140%;
    vertical-align: baseline;
    margin-top: 12px;
  }

  .link {
    margin-top: 15px;

    a {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: $blueMediumDark;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }

  .box-graph {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    font-size: 0.929rem;
    color: $gray;

    .graph-item {
      width: 100px;
    }

    .graph-item-01 {
      height: 138px;
      background: $violet;
      margin-bottom: 5px;
      position: relative;
      border-radius: 2px;
      transition: 0.3s;

      .box-brace {
        position: absolute;
        width: 20px;
        height: 100%;
        right: -55px;

        .block-brace {
          width: 50%;
          height: 100%;
          border: 1px solid #9356f8;
          border-left-width: 0;
          border-radius: 0 5px 5px 0;
          position: relative;

          &:after {
            content: '';
            width: 100%;
            height: 1px;
            background: #9356f8;
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .graph-item-02 {
      height: 138px;
      background: $health;
      border-radius: 2px;
      transition: 0.3s;
    }

    .graph-top {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .graph-text {
        margin-bottom: 10px;

        .graph-text-arrow {
          margin-left: -5px;
          font-size: 26px;
        }
      }

      h2 {
        margin: 0;
        display: flex;
        font-size: 30px;
        color: $blueDark;
      }
    }

    .graph-title {
      font-size: 14px;
      color: $blueDark;
      text-align: center;
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 959px) {
  .personal-risk-container {
    .box-graph {
      .graph-item {
        width: 60px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .personal-risk-container {
    h2 {
      font-size: 16px;

      br {
        display: none;
      }
    }

    .block-location-text {
      font-size: 14px;
    }

    .box-graph {
      margin-left: 0;
      margin-right: 0;

      .graph-item-01::after {
        right: -40px;
      }
    }
  }
}
