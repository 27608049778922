@import '../../../../assets/media';

.distribution {
	// height: 500px;
	// padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: relative;

	.distribution-rate-block {
		display: flex;
		flex: 1 0 auto;
		gap: 20px;
		@include mobile-down {
			flex-direction: column;
		}
	}

	.bar-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.blured {
		filter: blur(15px);
		user-select: none;
		@include mobile-down {
			filter: blur(10px);
		}

		&:hover {
			filter: blur(12px);
			@include mobile-down {
				filter: blur(8px);
			}
		}
	}

	.demo-message {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;

		.img-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 200px;
			height: 200px;
			padding-bottom: 25px;
			background: radial-gradient(
				50% 50% at 50% 50%,
				rgba(126, 242, 243, 0.4) 0%,
				rgba(126, 242, 243, 0) 100%
			);
		}
	}
}

.no-data {
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}
