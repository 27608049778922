.menu-mobile.report-mobile-menu {
	position: relative;
	box-sizing: border-box;

	.menu-icon {
		width: 40px;
		height: 40px;
		background: transparent;
		border: none;
		margin: 0;
		padding: 0;
		background: #dee2e5;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: #9356f8;
		position: relative;
		z-index: 10;
	}

	.menu-content {
		width: 0;
		height: 0;
		opacity: 0;
		background: #dee2e5;
		overflow: hidden;
		border-radius: 10px 10px 0 10px;
		transition: width 0.3s, opacity 0.3s;
		display: flex;
		flex-direction: column;
		align-items: start;
		padding: 20px;
		position: absolute;
		top: 20px;
		right: 0;
		z-index: 5;
	}

	&.menu-open {
		&::after {
			content: "";
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.9);
		}

		.menu-icon {
			background: #cacaca;
		}

		.menu-content {
			width: 240px;
			height: auto;
			opacity: 1;
		}
	}
}
