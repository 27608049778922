.location-map {
	.btn-wrap {
		display: flex;
		align-items: center;
		gap: 16px;
		flex-wrap: wrap;
	}

	.map-container {
		height: 400px;
		margin-top: 16px;
	}

	:global {
		.marker-popup-info {
			.mapboxgl-popup-content {
				padding: 10px 14px;
				box-sizing: border-box;
			}

			.mapboxgl-popup-close-button {
				font-size: 24px;
				padding: 0 8px;
			}
			h4 {
				font-size: 18px;
				margin-top: 10px;
			}
		}
	}
}
