@import "../../assets/colors";

.personal-risk-drivers-container {
    .box-graph {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 33px;
    }

    .graph-circle {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 140px;
        width: 40%;
        margin: 2.5rem 0 1.5rem;

        .block-text {
            height: 30px;
            margin-top: 0.6rem;
            font-size: 0.928rem;
            text-align: center;

            span {
                display: block;
                color: $blueDark;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .graph-info {
        width: 53%;
    }

    .graph-color-item {
        border-top: 1px solid rgba(35, 53, 68, 0.1);
        padding-top: 1.3rem;

        &:first-of-type {
            border: none;
        }
    }

    .graph-color-data {
        display: flex;
        color: #233544;

        h2 {
            font-weight: 400;
            line-height: 1;
            margin-top: -3px;
            margin-bottom: 11px;
            flex-basis: 100%;
        }

        span {
            font-size: 1rem;
            vertical-align: baseline;
        }

        .tag {
            display: block;
            font-size: 0.857rem;
            color: $gray;
            line-height: 140%;
            margin-top: 8px;
        }
    }
}

@media only screen and (max-width: 1199px) {
    // .personal-risk-container .box-graph .graph-item {
    //     width: 50px;
    // }
}
