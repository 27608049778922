:global {
	.risk-safety-summary-container {
		:local {
			.hospitals {
				// margin-top: 16px;
				// padding-bottom: 16px;
				display: flex;
				flex-direction: column;
				gap: 12px;
				.contacts-wrap {
					// margin-top: 12px;
				}
			}
		}
	}
}
