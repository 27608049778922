@import '../../../../assets/media';

.box-map {
	// TODO: need to check?
	// padding: 20px;
}

.block-map-scale {
	display: flex;
	justify-content: space-between;

	@include mobile-down {
		flex-direction: column;
	}

	.scale-item {
		width: 10%;
		height: 72px;
		position: relative;
		font-size: 13px;
		color: var(--text-color-secondary);

		@include mobile-down {
			width: 100%;
			min-height: 40px;
			height: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: none;

			&:not(.scale-item-with-data) {
				display: none;
			}

			&.scale-item-with-data {
				border-top: 1px solid rgba(35, 53, 68, 0.1);
				border-bottom: 1px solid rgba(35, 53, 68, 0.1);
			}

			&.scale-item-with-data ~ .scale-item-with-data {
				border-top-width: 0;
				border-bottom: 1px solid rgba(35, 53, 68, 0.1);
			}

			.box-stat {
				display: flex;
				align-items: center;
			}
		}

		.heatmap {
			display: block;
			height: 10px;
			border-radius: 10px;
			margin-top: 10px;

			@include mobile-down {
				width: 20px;
				margin-top: 0;
				margin-right: 10px;
			}
		}

		.location-title {
			padding: 7px;
		}

		.stat-num-devider {
			display: none;

			@include mobile-down {
				display: inline-block !important;
				position: static;
				transform: none;
				margin-right: 10px;
			}
		}

		&:not(:last-of-type) .stat-num-two {
			display: none;
		}

		.stat-num {
			width: 100%;
			position: absolute;
			top: -16px;
			left: 0;
			color: var(--text-color-primary);
			text-align: center;

			@include mobile-down {
				position: static;
				text-align: left;
			}
		}

		.stat-num-two {
			left: 100%;
		}
	}
}
