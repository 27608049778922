div.alert {
	width: 100%;
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
	padding: 20px;
	display: flex;
	justify-content: flex-start;
	gap: 8px;
	color: var(--text-color-primary);
	box-sizing: border-box;

	&.type-info {
		background: #e3d8f8;
	}

	&.type-warning {
		background: #ffcb0033;
		color: var(--text-color-secondary);

		.icon {
			color: #ffcb00;
		}
	}

	&.type-errors {
		background: #ff000033;
		color: #390505;
	}

	&.type-success {
		background: #4caf50;
	}

	.icon-wrap {
		display: flex;
		align-items: flex-start;
		color: var(--violet);
		margin-left: -10px;
	}

	.icon {
		font-size: 26px;
		margin-top: -3px;
	}

	.text-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 5px;
	}

	.title {
		font-size: 20px;
		color: inherit;
		font-weight: 400;
		line-height: normal;
	}

	.sub-title {
		font-size: 16px;
		color: inherit;
		line-height: 1.4;
	}

	.text {
		font-size: 13px;
		color: inherit;
	}

	.button-wrap {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: auto;
	}
}
